<template>
  <div class="table">
    <div class="table-container"
         :style="{ '--border-color': borderColor || '#E7E8F2' }"
         :class="{
            'normal': type === 'normal',
            'customers': type === 'customers',
            'customers-checkbox': type === 'customers-checkbox',
            'cars': type === 'cars',
            'feed': type === 'feed',
            'campaign': type === 'campaign',
            'campaign_audience': type === 'campaign_audience',
        }"
    >
      <div class="table-container__header">
        <slot name="tHead"/>
      </div>
      <div class="table-container__body ez-slim-scroll">
        <slot name="tBody"/>
      </div>
      <slot name="tFooter"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "Table",
  props: {
    type: {
      type: String,
      enum: ['customers', 'cars', 'normal', 'feed', 'campaign'],
      default: 'customers'
    },
    borderColor: {
      type: String,
      default: '#E7E8F2'
    },
  },
}
</script>

<style lang="scss" scoped>
.table {
  $border-color: var(--border-color);

  height: 100%;
  display: flex;
  flex-direction: column;

  .table-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    & > div:not(.t-footer) {
      & > div {
        display: grid;

        .action {
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    &__header {
      & > div {
        text-transform: uppercase;
        font-size: .6rem;
        padding: 15px 10px;
        color: color(bg-black3);
        position: sticky;
        top: 0;
        background: color(bg-fill);
        width: 100%;
        z-index: 999;

        & > div {
          font-weight: $font-medium;
          display: flex;
          align-items: center;
        }
      }

      .email {
        grid-column: 4 / 6;
      }
    }

    &__body {
      overflow: auto;
      flex: 1;
      word-break: break-word;
      color: color(text);
      font-size: .9rem;

      a {
        color: color(text);
      }
    }

    &.normal {
      & > div {
        & > div {
          grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
        }
      }
    }

    &.customers {

      & > div {

        & > div {
          grid-template-columns: 50px repeat(auto-fit, minmax(50px, 1fr));
        }
      }
    }

    &.customers-checkbox {

      & > div {
        & > div {
          grid-template-columns: minmax(40px, max-content) 50px repeat(auto-fit, minmax(50px, 1fr));
        }
      }
    }

    &.cars {
      & > div {
        .t-header {
          @include breakpoint-width-down(small) {
            display: none;
          }
        }

        .t-body {
          @include breakpoint-width-down(small) {
            height: 80px;
            position: relative;
            padding: 0;
            .img-thumbs {
              grid-area: image;

              img {
                width: 100%;
                height: 100%;
                border-radius: 0;
              }
            }
            .title {
              grid-area: title;
            }

            .price {
              grid-area: price;
            }

            .color {
              grid-area: color;
            }

            .info {
              display: none;
            }

            .stock-number {
              grid-area: stock;
            }

            .mileage {
              grid-area: mileage;
            }

            .status {
              grid-area: status;
            }

            .sell-status {
              display: none;
            }

            .action {
              position: absolute;
              right: 5px;
            }
          }
        }

        & > div {
          grid-template-columns: 60px 290px repeat(auto-fit, minmax(50px, 1fr));
          gap: 1px;
          @include breakpoint-width-down(small) {
            gap: 1px 10px;
            grid-template-columns: 110px repeat(4, auto);
            grid-template-areas:  "image title title title title "
                                  "image price mileage mileage . "
                                  "image stock color status ."
          }
        }
      }
    }

    &.feed {
      & > div {
        & > div {
          grid-template-columns: repeat(auto-fit, 300px);
        }
      }
    }

    &.campaign {
      & > div {
        & > div {
          grid-template-columns: 320px repeat(auto-fit, minmax(50px, 1fr));
          gap: 1px;
        }
      }
    }

    &.campaign_audience {
      & > div {
        & > div {
          grid-template-columns: 50px 350px repeat(auto-fit, minmax(50px, 1fr));
          gap: 1px;
        }
      }
    }

    .t-body {
      background: white;
      border: 1.5px solid $border-color;
      height: 60px;
      border-radius: 5px;
      margin-bottom: 3px;
      padding: 0 10px;

      &:hover {
        //border-color: color(primary-hover-dark);
        box-shadow: inset 0 0 .8rem rgba(color(bg-blue-dark), .1);

      }

      & > div {
        display: flex;
        align-items: center;
        font-weight: $font-regular;
        font-size: .9em;
      }

      .initials-letters {
        span {
          color: white;
          width: 35px;
          height: 35px;
          font-size: .9em;
          background: color(bg-black3);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-weight: $font-bold;
          text-transform: uppercase;
          color: color(bg-white);
        }
      }

      .img-thumbs {
        img {
          width: 60px;
          border-radius: .3rem;
        }
      }

      .email {
        grid-column: 4 / 6;
      }

      .action {
        display: flex;

        .icon {
        }
      }
    }

    .t-footer {
      padding: 10px 0;
    }
  }
}
</style>
